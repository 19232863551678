import user from '@/api/modules/user';
import { allLsObj } from '@/common/utils/ls';
import { getUrlParam } from '@/utils/index';
import { requestUserInfo, getUser } from '@/utils/user';
import { setWechatCode } from '@/utils/wechatCode';

export function toAuth(isSkip) {
  const haveToken = allLsObj.global.token.get();
  if (haveToken || isSkip) {
    return Promise.resolve(true);
  }
  const redirectUrl = window.location.origin;
  const ua = navigator.userAgent.toLowerCase();
  const matches = ua.match(/MicroMessenger/i) || [];

  if (matches[0] === 'micromessenger') {
    const { code } = getUrlParam();
    if (!code) {
      const href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${process.env.VUE_APP_WX_ID}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=0#wechat_redirect`;
      window.location.href = href;
      return Promise.resolve(false);
    }
    // code 存在，直接保存coding 将code 从url删除
    history.replaceState(history.state, document.title, location.href.replace(/([?&])?code\s*=\s*[^?&#$]*[&]?/, '$1'));
    allLsObj.global.wechatWebAppCode.set(code);
    return user
      .bindWechatWebUnionId(code)
      .then(res => {
        const data: any = res?.data?.data;
        const token = data ? data.token || '' : '';
        if (token) {
          allLsObj.global.token.set(token);
          if (!getUser()) {
            return requestUserInfo().then(() => {
              return true;
            });
          }
        }
        return true;
      })
      .catch(() => {
        return true;
      });
  }
  return Promise.resolve(true);
}

export function toAuthPayment(isSkip) {
  if (isSkip) {
    return Promise.resolve(true);
  }
  const redirectUrl = `${window.location.origin}/payment.html`;
  const ua = navigator.userAgent.toLowerCase();
  const matches = ua.match(/MicroMessenger/i) || [];

  if (matches[0] === 'micromessenger') {
    const { code } = getUrlParam();
    if (!code) {
      const href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${process.env.VUE_APP_WX_ID}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_base&state=0#wechat_redirect`;
      window.location.href = href;
      return Promise.resolve(false);
    }
    // code 存在，直接保存coding 将code 从url删除
    history.replaceState(history.state, document.title, location.href.replace(/([?&])?code\s*=\s*[^?&#$]*[&]?/, '$1'));
    setWechatCode(code);
  }
  return Promise.resolve(true);
}
