import localForage from 'localforage';
import Vant from 'vant';
import { createApp, h, resolveComponent } from 'vue';
import { allLsObj } from '@/common/utils/ls';
// ARMS埋点
import '@/plugin/alilog';
// 神策埋点
import RadioGroup from '@/components/RadioGroup/index.vue';
import sensors from '@/sensor/instance';
import { toAuth } from '@/utils/auto';
// eslint-disable-next-line import/order
import router from './router/index';
import store from './store/index';
import './permission';
import 'vant/lib/index.css';
import '@/assets/styles/index.scss';
// eslint-disable-next-line import/no-extraneous-dependencies, import/order
import ElementPlus from 'element-plus';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'element-plus/dist/index.css';
// eslint-disable-next-line import/order, import/no-extraneous-dependencies
import zhCn from 'element-plus/es/locale/lang/zh-cn';

toAuth(allLsObj.global.islogout.get())
  .then(load => {
    if (load) {
      const app = createApp({
        render() {
          return h(resolveComponent('router-view'), {});
        },
      });

      // 注册全局组件
      app.component('RadioGroup', RadioGroup);

      app.use(router).use(store).use(Vant).use(ElementPlus, {
        locale: zhCn,
      });

      app.config.globalProperties.$sensors = sensors;
      app.config.globalProperties.$forage = localForage;

      app.mount('#app');
    }
  })
  .finally(() => {
    // 调授权埋点
    sensors.track('ones_result', {
      message: allLsObj.global.userId.get(),
      message1: 'authorization',
    });
  });
