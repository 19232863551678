import { createStore } from 'vuex';
import community from './modules/community';
import coreatorPermissions from './modules/coreatorPermissions';
import prompt from './modules/prompt';

const store = createStore({
  modules: {
    coreatorPermissions,
    prompt,
    community,
  },
});

export default store;
