import request from '@/utils/request';

// 关键字搜索社区列表
export function keyWordsList(options) {
  return request({
    url: '/goose/chat/aiPrompt/queryByKeyword',
    method: 'post',
    data: options,
  });
}

// 查询所有已发布的prompt
export function recommendPromptList(options) {
  return request({
    url: '/goose/chat/aiPrompt/queryPublish',
    method: 'post',
    data: {
      sortKey: options.sortKey,
    },
    // 一次性返回200条.切换tab过快的时候需要取消该未结束的请求
    cancelToken: options.token,
  });
}

// 查询用户收藏的prompt
export function collectPromptList(options) {
  return request({
    url: '/goose/chat/userOperate/queryCollect',
    method: 'post',
    data: options,
  });
}

// 查询用户发布的prompt
export function publishPromptList(options) {
  return request({
    url: '/goose/chat/aiPrompt/queryCreatorPublish',
    method: 'post',
    data: options,
  });
}

// 查询用户草稿的prompt
export function draftPromptList(options) {
  return request({
    url: '/goose/chat/aiPrompt/queryDraft',
    method: 'post',
    data: options,
  });
}

// 批量删除草稿箱
export function delMoreDraftsPrompt(options) {
  return request({
    url: '/goose/chat/aiPrompt/batchDelete',
    method: 'post',
    data: options,
  });
}

// 新增prompt收藏
export function addPromptCollect(options) {
  return request({
    url: '/goose/chat/userOperate/saveCollect',
    method: 'post',
    data: options,
  });
}

// 删除prompt收藏
export function delPromptCollect(options) {
  return request({
    url: '/goose/chat/userOperate/deleteCollect',
    method: 'post',
    data: options,
  });
}

// 点赞接口
export function handleLike(options) {
  return request({
    url: '/goose/chat/userOperate/saveLike',
    method: 'post',
    data: options,
  });
}

// (取消点赞)
export function handleDelLike(options) {
  return request({
    url: '/goose/chat/userOperate/deleteLike',
    method: 'post',
    data: options,
  });
}
