import type { urlParamObj } from '@/types/api/login';
import { ZoneTypeValueItem } from '@/types/community/interface';

// 判断是否是微信环境
export function isWeChatEnv(): boolean {
  const ua: string = window.navigator.userAgent.toLowerCase();
  return /microMessenger/i.test(ua); // 判定为true则是微信浏览器，false则不是
}

// 获取url里面的参数
export function getUrlParam() {
  const url = location.search;
  const theRequest: urlParamObj = {};
  if (url.indexOf('?') !== -1) {
    const str = url.slice(1);
    const strs = str.split('&');
    for (let i = 0; i < strs.length; i++) {
      // eslint-disable-next-line prefer-destructuring
      theRequest[strs[i].split('=')[0]] = strs[i].split('=')[1];
    }
  }
  return theRequest;
}

// 生成唯一Id
export function createUniqueString() {
  const timestamp = `${+new Date()}`;
  const randomNum = `${Math.floor((1 + Math.random()) * 65536)}`;
  return (+(randomNum + timestamp)).toString(32);
}

// 判断是否移动端
export function isMobile() {
  if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
    return true; // 手机端
  }
  return false;
}

/**
 * 转对象
 * @param arr 数组对象
 */
export function handleArrayToMap(arr: Array<ZoneTypeValueItem>): ZoneTypeValueItem | object {
  const map: ZoneTypeValueItem | object = {};
  arr.forEach(item => {
    map[item.type] = item.label;
  });
  return map;
}

/**
 * Merges two objects, giving the last one precedence
 * @param {Object} target
 * @param {(Object|Array)} source
 * @returns {Object}
 */
export function objectMerge(target, source) {
  if (typeof target !== 'object') {
    target = {};
  }
  if (Array.isArray(source)) {
    return source.slice();
  }
  Object.keys(source).forEach(property => {
    const sourceProperty = source[property];
    if (typeof sourceProperty === 'object') {
      target[property] = objectMerge(target[property], sourceProperty);
    } else {
      target[property] = sourceProperty;
    }
  });
  return target;
}

// 判断结果是否图片
export function handleImgJudge(content: string) {
  if (!content) {
    return false;
  }
  if (typeof content !== 'string') {
    return false;
  }
  const start = ['http', 'https'];
  const end = ['.png', '.jpg'];
  if ((content.indexOf(start[0]) === 0 || content.indexOf(start[1]) === 0) && (content.indexOf(end[0]) === content.length - 4 || content.indexOf(end[1]) === content.length - 4)) {
    return true;
  }
  return false;
}

// 判断图片是否存在
export function handleCheckImgExists(url: string) {
  const ImgObj = new Image() as any;
  ImgObj.src = url;
  if (ImgObj.fileSize > 0 || (ImgObj.width > 0 && ImgObj.height > 0)) {
    return true;
  }
  return false;
}
