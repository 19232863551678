import { sendCodeTypeParams, registerAndLoginTypeParams, switchParams } from '@/types/api/login';
import request from '@/utils/request';

// 获取图形验证码
export function getImgCode() {
  return request({
    url: '/goose/chat/aiAuth/imgCode',
    method: 'get',
    headers: {
      isToken: false,
    },
    responseType: 'blob',
  });
}

// 登录
export function registerAndLogin(data: registerAndLoginTypeParams) {
  return request({
    url: '/goose/chat/aiAuth/registerAndLogin',
    method: 'post',
    headers: {
      isToken: false,
    },
    data,
    showResultMsg: true,
    resultMsg: '登录成功',
  });
}

// 登录-发送动态码
export function sendPhoneCode(data: sendCodeTypeParams) {
  return request({
    url: '/goose/chat/aiAuth/sendPhoneCode',
    method: 'post',
    headers: {
      isToken: false,
    },
    data,
    showResultMsg: true,
    resultMsg: '获取验证码成功',
  });
}

// 付费开关
export function getSwitchResult(data: switchParams) {
  return request({
    url: '/goose/chat/config/getSwitchResult',
    method: 'post',
    data,
    showResultMsg: true,
    resultMsg: '',
  });
}
