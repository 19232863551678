import request from '@/utils/request';

// 保存prompt
export function savePrompt(options) {
  return request({
    url: '/goose/chat/aiPrompt/save',
    method: 'post',
    data: options,
  });
}

// 修改prompt
export function updatePrompt(options) {
  return request({
    url: '/goose/chat/aiPrompt/update',
    method: 'post',
    data: options,
  });
}
