import 'vant/es/notify/style';
import axios from 'axios';
import type { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { ElMessage } from 'element-plus';
import userApi from '@/api/modules/user';
import { allLsObj } from '@/common/utils/ls';
import Model from '@/plugin/modal';
import router from '@/router/index';
import emitter from '@/utils/eventBus';
import { isMobile } from '@/utils/index';

// 一个标志位，防止多个接口调用多次打开login
export const isRelogin = { show: false };

// 创建一个全局的CancelToken源, 用于终止指定请求
export const CancelToken = axios.CancelToken;

const errorCode = {
  401: '认证失败，无法访问系统资源',
  403: '当前操作没有权限',
  404: '访问资源不存在',
  default: '系统未知错误，请反馈给管理员',
};

const ResponseCode = {
  success: 'COMCC00000', // 正常返回
  failCode: ['GOOERR401', '401'],
  errCode: ['GOOERR014', 'APPSTES500'], // 需通知的错误码
};

function errorLogin() {
  return Model.alert('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
    confirmButtonText: '重新登录',
    type: 'warning',
  }).then(() => {
    return userApi.logout().finally(() => {
      allLsObj.global.token.remove();
      location.reload();
      isRelogin.show = false;
    });
  });
}

// 错误处理
function errorTip(error, config) {
  const { message, code } = error || {};
  if (ResponseCode.failCode.includes(code)) {
    if (!isRelogin.show) {
      isRelogin.show = true;
      if (isMobile()) {
        return errorLogin();
      }
      emitter.emit('openDialog');
      router.push({ name: 'Home' });
    }
    // PC端验证码过期
    if (!isMobile() && code === '401') {
      allLsObj.global.token.remove();
      emitter.emit('openDialog');
    }
    return Promise.reject(error);
  }
  if ((config && config.showErrorMsg !== false) || ResponseCode.errCode.includes(code)) {
    if (isMobile()) {
      Model.notifyError(message);
    } else {
      ElMessage({
        message,
        type: 'error',
      });
    }
  }
  return Promise.reject(error);
}

function interceptorRequest(req: any) {
  // 是否需要设置 token
  const isToken = (req.headers || {}).isToken === false;
  const token = allLsObj.global.token.get();
  if (token && !isToken) {
    req.headers.token = token;
  }
  return req;
}

function interceptorResponse(res: any): Promise<any> {
  // 二进制数据则直接返回 AxiosResponse
  if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
    return Promise.resolve(res);
  }
  const { data } = res;
  const statusInfo = data?.statusInfo;
  const code = statusInfo?.code || data?.code || ResponseCode.success;
  // 获取错误信息
  const msg = errorCode[code] || statusInfo?.message || data?.msg || errorCode.default;
  if (code === ResponseCode.success) {
    // const config = res.config || {};
    if (res.config.showResultMsg === true) {
      // Model.notifySuccess(config.resultMsg || msg);
    }
    return Promise.resolve({ data: res.data, response: res });
  }
  return errorTip({ message: msg, code }, res.config);
}

function errorHandler(error: any): Promise<any> {
  let { message } = error;
  const response: any = error?.response || {};
  const data = response.data || {};
  const code = data.code || response.status;
  if (message === 'Network Error') {
    message = '后端接口连接异常';
  } else if (message.includes('timeout')) {
    message = '系统接口请求超时';
  } else if (message.includes('Request failed with status code')) {
    message = `系统接口${message.slice(message.length - 3)}异常`;
  }
  return errorTip({ message, code, type: 'error' }, error.config);
}

// 创建axios实例
const instance: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 60 * 1000,
  headers: {
    'Content-Type': 'application/json;',
  },
});

instance.interceptors.request.use(interceptorRequest, errorHandler);
instance.interceptors.response.use(interceptorResponse, errorHandler);

export default function (options: any) {
  return instance(options);
}
