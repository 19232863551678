import type { RouteLocationNormalized } from 'vue-router';
import { allLsObj } from '@/common/utils/ls';
import { isMobile } from '@/utils/index';
import { getUser, requestUserInfo, handleIsOpen } from '@/utils/user';
import router from './router';

const whiteList = ['/login', '/auth-redirect', '/bind', '/register', '/401', '/registerAgreement', '/privacyAgreement'];

router.beforeEach(async (to: RouteLocationNormalized) => {
  // 对应错误不需要做拦截
  const token = allLsObj.global.token.get();

  if (isMobile()) {
    if (token) {
      handleIsOpen().then(res => {
        const { data } = res.data;
        allLsObj.global.isOpen.set(data.status);
      });
      if (to.name === 'Login') {
        return { name: 'Chat' };
      }
      /* has token */
      if (!getUser()) {
        // 获取用户信息
        await requestUserInfo();
        return true;
      }
      return true;
    }
    // 没有token
    // eslint-disable-next-line no-lonely-if
    if (whiteList.includes(to.path)) {
      // 在免登录白名单，直接进入cx
      return true;
    }
    return { name: 'Login' };
  }
  if (to.name === 'Login') {
    return { name: 'Index' };
  }
});
