import request from '@/utils/request';

export default {
  // 登录-获取微信用户信息
  bindWechatWebUnionId: (code: string) => {
    return request({
      url: '/goose/chat/aiAuth/bindWechatWebUnionId',
      method: 'post',
      data: {
        wechatWebAppCode: code,
      },
    });
  },
  // 获取用户信息
  info: () => {
    return request({
      url: '/goose/chat/aiUser/getUserInfo',
      method: 'get',
    });
  },
  // 退出登录
  logout: () => {
    return request({
      url: '/goose/chat/aiAuth/logout',
      method: 'post',
    });
  },
  handeleDiscount: (code: string) => {
    return request({
      url: '/goose/chat/aiAuth/bindWechatWebUnionId111',
      method: 'post',
      data: {
        wechatWebAppCode: code,
      },
    });
  },
  // 模型设置
  handleModelSet: (apiType: number) => {
    return request({
      url: '/goose/chat/aiUser/updateApi',
      method: 'post',
      data: {
        apiType,
      },
    });
  },
  // 验证用户是否存在
  handleCheckUser: phone => {
    return request({
      url: '/goose/chat/aiUser/isExist',
      headers: {
        isToken: false,
      },
      method: 'post',
      data: {
        phone,
      },
    });
  },
  // 协议
  handleGetAgreement: (type: string) => {
    return request({
      url: `/goose/chat/chatGPT/sign?type=${type}`,
      headers: {
        isToken: false,
      },
      method: 'get',
    });
  },
};
