import { savePrompt, updatePrompt } from '@/api/modules/prompt';

export default {
  namespaced: true,
  state: {
    // 预设置保存form
    preSetForm: null,
    // imgsUrl AI绘画
    imgsUrl: null,
    // lastQuestionId
    questionId: null,
    // 最后一次会话id
    chatId: null,
  },
  mutations: {
    SET_PRESETFORM(state, preSetForm: object) {
      state.preSetForm = preSetForm;
    },
    SET_IMGSURL(state, imgsUrl: string) {
      state.imgsUrl = imgsUrl;
    },
    SET_QUESTIONID(state, questionId: string | number) {
      state.questionId = questionId;
    },
    SET_CHATID(state, chatId: string | number) {
      state.chatId = chatId;
    },
  },
  actions: {
    /**
     * 保存prompt
     */
    sendSavePrompt({ commit }, options) {
      return savePrompt(options).then(({ data }) => {
        return data;
      });
    },
    /**
     * 修改prompt
     */
    sendUpdatePrompt({ commit }, options) {
      return updatePrompt(options).then(data => {
        return data;
      });
    },
  },
};
