import { coreatorPermissions } from '@/api/modules/coreatorPermissions';

export default {
  namespaced: true,
  actions: {
    /**
     * 申请创作者权限
     * @param param0
     * @param options
     */
    sendCoreatorPermissions({ commit }, options) {
      return coreatorPermissions(options).then(({ data }) => {
        return data;
      });
    },
  },
};
