import request from '@/utils/request';

// 申请创作者权限
export function coreatorPermissions(options) {
  return request({
    url: '/goose/chat/aiCreator/apply',
    method: 'post',
    data: options,
  });
}
