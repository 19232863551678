import user from '@/api/modules/user';
import { getSwitchResult } from '@/pages/login/api';

// 用户信息直接用内存存储，不需要的时候直接
let userInfo = null;
export function getUser() {
  return userInfo;
}

export function setUser(_userInfo) {
  userInfo = _userInfo;
}

export function removeUser() {
  userInfo = null;
}

export function requestUserInfo() {
  return user.info().then(({ data }) => {
    const userInfoTemp = data.data || {};
    setUser(userInfoTemp);
  });
}

export function handleIsOpen() {
  const obj = {
    key: 'freeSwitch',
  };
  return getSwitchResult(obj);
}
