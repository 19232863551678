import { keyWordsList, recommendPromptList, collectPromptList, publishPromptList, draftPromptList, delMoreDraftsPrompt, addPromptCollect, delPromptCollect, handleLike, handleDelLike } from '@/api/modules/community';
import storeUtils from '@/store/utils';

export default {
  namespaced: true,
  state: {
    // 社区列表集合
    dataList: [],
  },
  mutations: {
    ...storeUtils.mutations,
    SET_DATALIST(state, map) {
      state.dataList = map;
    },
    SET_DATALISTPC(state, map) {
      state.dataList.push(...map);
    },
  },
  actions: {
    sendKeyWordsList({ commit }, options) {
      return keyWordsList({
        searchKey: options.searchKey,
        pageNo: options.pageNo,
        pageSize: options.pageSize,
      }).then(({ data }) => {
        if (data.data.length > 0) {
          if (!options.flag) {
            commit('SET_DATALISTPC', data.data);
            return data;
          }
          commit('setState', {
            dataList: data?.data || [],
          });
        } else {
          return data;
        }
      });
    },
    /**
     * 查询所有已发布的prompt => 推荐列表
     * @param param0
     */
    getRecommendPromptList({ commit }, options) {
      return recommendPromptList(options).then(({ data }) => {
        commit('setState', {
          dataList: data?.data || [],
        });
      });
    },
    /**
     * 查询用户收藏的prompt
     * @param param0
     */
    getCollectPromptList({ commit }, options) {
      return collectPromptList({
        pageNo: options.pageNo,
        pageSize: options.pageSize,
      }).then(({ data }) => {
        if (data.data.length > 0) {
          if (!options.flag) {
            commit('SET_DATALISTPC', data.data);
            return data;
          }
          commit('setState', {
            dataList: data?.data || [],
          });
        } else {
          return data;
        }
      });
    },
    /**
     * 查询用户发布的prompt
     * @param param0
     */
    getPublishPromptList({ commit }, options) {
      return publishPromptList(options).then(({ data }) => {
        if (data.data.length > 0) {
          if (!options.flag) {
            commit('SET_DATALISTPC', data.data);
            return data;
          }
          commit('setState', {
            dataList: data?.data || [],
          });
        } else {
          return data;
        }
      });
    },
    /**
     * 查询用户草稿的prompt
     * @param param0
     */
    getDraftPromptList({ commit }, options) {
      return draftPromptList({
        pageNo: options.pageNo,
        pageSize: options.pageSize,
      }).then(({ data }) => {
        if (data.data.length > 0) {
          if (!options.flag) {
            commit('SET_DATALISTPC', data.data);
            return data;
          }
          commit('setState', {
            dataList: data?.data || [],
          });
        } else {
          return data;
        }
      });
    },
    /**
     * 批量删除草稿箱
     * @param param0
     * @param options
     * @returns
     */
    sendDelMoreDraftsPrompt({ commit }, options) {
      return delMoreDraftsPrompt(options).then(({ data }) => {
        return data;
      });
    },
    /**
     * 新增prompt收藏
     * @param param0
     * @param promptId
     * @returns
     */
    sendAddPromptCollect({ commit }, options) {
      return addPromptCollect(options).then(({ data }) => {
        return data;
      });
    },
    /**
     * 删除prompt收藏
     * @param param0
     * @param promptId
     * @returns
     */
    sendDelPromptCollect({ commit }, options) {
      return delPromptCollect(options).then(({ data }) => {
        return data;
      });
    },
    /**
     *
     * @param param0 点赞
     * @param options
     * @returns
     */
    sendLike({ commit }, options) {
      return handleLike(options).then(({ data }) => {
        return data;
      });
    },
    /**
     *
     * @param param0 取消点赞
     * @param options
     * @returns
     */
    sendDelLike({ commit }, options) {
      return handleDelLike(options).then(({ data }) => {
        return data;
      });
    },
  },
};
